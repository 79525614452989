import axios from "axios";
import React, { useEffect, useState } from "react";
import htmlParser from "react-html-parser";
import { useLocation, useParams } from "react-router-dom";
import kid from "../../assets/images/kid.jpg";

const AboutParticularInstitute = () => {

  const location= useParams()
  
  console.log(location)
  const [aboutParticularInstDesc, setAboutParticularInstDesc] = useState([]);
  const getAboutParticulaInstitute = async () => {
    try {
      // const response = await axios.get('http://app.rollingnotes.in/backend/get_institute_description.php')
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_APP_API_URL}/backend/get_institute_description.php`
      );
     
      setAboutParticularInstDesc(response?.data?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAboutParticulaInstitute();
  }, []);
  return (
    <>
    
      <section className="college_intro">
        {/* <div style={{height:"300px"}}>
          <img src={kid} alt="kid" height={300} width={"100%"} />
        </div> */}
        <div className="container">
      
          <div className="row aboutInstitute">
            <div className="col-md-12">
              <div className="intro_inner">
                <div className="content">
                  {aboutParticularInstDesc?.map((item) => {
                    return (
                      <>
                  
                        {item?.institute_id &&
                          item?.institute_id === parseInt(location?.InstituteId) && (
                            <>
                              <h2 className="my-2">{item?.title}</h2>
                              <div className="content mt-5">
                                <p className="my-4">
                                  {htmlParser(item?.description_c)}
                                </p>
                              </div>
                            </>
                          )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutParticularInstitute;
