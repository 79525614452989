import React, { useState, useEffect } from "react";
import "./Modals.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTimerVal } from "../../feature/actions/timerActions";
import { Helmet } from "react-helmet";
import AdsComponent from "../../AdsComponent/AdsComponent";

const AdsModalForInstitute = ({
  setTime,
  show,
  close,
  adsval,
  comesFrom,
  state,
  fornavigate,
  functionForClose,
  selectedcoursesemester,
  coursetypeselectedsemester,
  subjects,
  optionForPdfData,
  subjectName,
  selectedPdfName,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    institute,
    InstituteId,
    courses,
    sem,
    courseType,
    subject,
    modulee,
    pdf,
  } = useParams();
  const { timerData } = useSelector((state) => state?.timers);
  useEffect(() => {
    dispatch(fetchTimerVal);
  }, []);

  const [timeleft, setTimeleft] = useState(parseInt(timerData?.timing) || 3);
  const [title, setTitle] = useState("");
  useEffect(() => {
    setTimeleft(parseInt(timerData?.timing));
  }, [timerData]);

  // let pathValue = state?.institute;
  // let institutePath = pathValue?.replaceAll(" ", "-");
  // let coursesPath =state?.course?.replaceAll(" ", "-");
  // let Path = subjectName?.replaceAll(" ", "-");
  //semester logics for removing slash
  // let semesterArr = state?.semester?.split('');

  // for (let i = 0; i<semesterArr?.length; i++) {
  //   if (semesterArr[i] === "/") {
  //     semesterArr[i] = "";
  //   }
  // }
  // let semesterName = semesterArr?.join("");
  // let semesterNamePath = semesterName?.replaceAll(" ", "-");
  // let coursesTypePath = state?.coursetype?.replaceAll(" ", "-");
  // let pdfOptionNamePath = selectedPdfName?.replaceAll(" ", "-");

  const { addVal } = useSelector((state) => state.adsReducer);

  useEffect(() => {
    setTime(timeleft);
  }, []);

  useEffect(() => {
    if (timeleft === 0) {
      setTimeleft(null);

      close();
      if (fornavigate === "From-pdflink") {
        // navigate("/pdfforinstitute", {

        navigate(
          `/${institute}/${InstituteId}/${courses}/${sem}/${courseType}/${subject}/${modulee}/${pdf}`,
          {
            state: {
              institute: institute,
              course: courses,
              coursetype: courseType,
              bookname: subject,
              semester: sem,
              module: modulee,
              // subjectName: subjectName,
            },
          }
        );
      }
    }

    if (!timeleft) return;

    const intervalId = setInterval(() => {
      setTimeleft(timeleft - 1);
      setTime(timeleft - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeleft, fornavigate]);

  if (adsval === "0" || addVal === "0") {
    close();
  }

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  console.log(show, adsval, "add value")
  return (
    <>
      {adsval === "1" && addVal === "1"
        ? show && (
          <>
            <div className="modalContainer">
              <div
                className="modal_show"

                onClick={(e) => e.stopPropagation()}
              >
                <div style={{
                  position: 'relative',
                  margin: '20px 0 0 0',
                  height: '200px',
                  textAlign: 'center',
                  width: "100%",            // Full-width
                  height: "400px",          // Height of the banner
                  backgroundColor: "#f0f0f0", // Light grey background for the placeholder
                  display: "flex",          // Use flexbox to center content
                  justifyContent: "center", // Center horizontally
                  alignItems: "center",     // Center vertically
                  fontSize: "30px",         // Placeholder text size
                  color: "#666",            // Text color
                  border: "2px dashed #ccc", // Dashed border for placeholder effect
                }} className=""> Ad
                  <AdsComponent dataAdSlot="2597424317" />
                </div>
                <div style={{
                  margin: '0 0 20px 0',
                  width: "100%",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#f8f8f8",
                  fontSize: "18px",
                }}>
                  <button
                    className="modal-close"
                    style={{ color: "black" }}
                    onClick={() => {
                      close();
                      functionForClose(
                        selectedcoursesemester,
                        coursetypeselectedsemester,
                        subjects,
                        optionForPdfData
                      );
                    }}
                  >
                    Close
                  </button>
                  <div>

                    {timeleft != null && (
                      <h3 style={{ color: "black" }}>
                        00:{timeleft < 10 ? "0" + timeleft : timeleft}
                      </h3>
                    )}

                  </div>
                </div>

              </div>
            </div>
            <div className="modal_overlay" onClick={handleOverlayClick} />
          </>
        )
        : null}
    </>
  );
};

export default AdsModalForInstitute;
