import React from "react";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import BoardAHSEC from "./pages/Boards/BoardAHSEC";
import BoardCBSE from "./pages/Boards/BoardCBSE";
import BoardDibrugarh from "./pages/Boards/BoardDibrugarh";
import BoardGuwahati from "./pages/Boards/BoardGuwahati";
import Boards from "./pages/Boards/Boards";
import Feedback from "./pages/Feedback/Feedback";
import AHSEC from "./pages/Previous/AHSEC";
import CBSEPrev from "./pages/Previous/CBSEPrev";
import Dibrugarh from "./pages/Previous/Dibrugarh";
import Guwahati from "./pages/Previous/Guwahati";
import ICAI from "./pages/Previous/ICAI";
import SEBA from "./pages/Previous/SEBA";
import Previous from "./pages/Previous/Previous";
import StudyMaterial from "./pages/Study material/StudyMaterial";
import NCERT from "./pages/Study material/NCERT";
import Flowchart from "./pages/Study material/Flowcharts";
import ShortQuestions from "./pages/Study material/ShortQuestions";
import LongQuestions from "./pages/Study material/LongQuestions";
import Syllabus from "./pages/Study material/Syllabus";
import StudyNotes from "./pages/Study material/StudyNotes";
import SolvedPapers from "./pages/Study material/SolvedPapers";
import ImportantQuestions from "./pages/Study material/ImportantQuestions";
import Header from "./layouts/Header/Header";
import GoToTop from "./components/GoToTop";
import Career from "./pages/Career/Career";
import Footer from "./layouts/Footer/Footer";
import PreviousYearQuestions from "./pages/Study material/PreviousYearQuestions";
import Contribute from "./pages/Contribute/Contribute";
import PreviousYearQuestion from "./pages/Previous/PreviousYearQuestion";
import NewEarnWith from "./pages/Earn With us/NewEarnWith";
import CurrentAffair from "./pages/CurrentAffair/CurrentAffair";
import NewHome from "./pages/Home/NewHome";
import Institute from "./pages/Previous/Institute";
import SubjectModules from "./pages/Previous/SubjectModules";
import StudyMaterial1 from "./pages/Study material/StudyMaterial1";
import ContextHeaderProvider from "./feature/ContextForHeader/ContextHeaderProvider";
import PdfViewer from "./pages/Study material/PdfViewer.jsx";
import PdfForinstitute from "./pages/Previous/PdfForinstitute";
import { Privacy } from "./pages/Privacy.js";
import Newcomponent from "./pages/Previous/Newcomponent.jsx";
import { store } from "./feature/store.js";
import { Provider } from "react-redux";
import AboutParticularInstitute from "./pages/Previous/AboutParticularInstitute.jsx";
const App = () => {
  const { pathname, state } = useLocation();
  return (
    <>
      <Provider store={store}>
        <ContextHeaderProvider>
          {pathname !== "/" ? <Header /> : null}
          <Routes>
            <Route path="/" element={<NewHome />} />
            <Route path={`/:institute/:InstituteId`} element={<Institute />} />
            <Route
              path={`/:institute/:InstituteId/:courses`}
              element={<Institute />}
            />
            <Route
              path={`/:institute/:InstituteId/:courses/:sem`}
              element={<Institute />}
            />
            <Route
              path={`/:institute/:InstituteId/:courses/:sem/:courseType`}
              element={<Institute />}
            />

            <Route
              path={`/:institute/:InstituteId/:courses/:sem/:courseType/:subject`}
              element={<Institute />}
            />
            <Route
              path={`/:institute/:InstituteId/:courses/:sem/:courseType/:subject/:modulee`}
              element={<Institute />}
            />
            <Route
              path={`/:institute/:InstituteId/:courses/:sem/:courseType/:subject/:modulee/:pdf`}
              element={<PdfForinstitute />}
            />
            <Route
              path={"/aboutInstitute"}
              element={<AboutParticularInstitute />}
            />

            <Route path="/studymaterial" element={<StudyMaterial1 />} />
            <Route
              path={`/studymaterial/:module`}
              element={<StudyMaterial1 />}
            />
            <Route
              path={`/studymaterial/:module/:institute`}
              element={<StudyMaterial1 />}
            />
            <Route
              path={`/studymaterial/:module/:institute/:course`}
              element={<StudyMaterial1 />}
            />
            <Route
              path={`/studymaterial/:module/:institute/:course/:semester`}
              element={<StudyMaterial1 />}
            />
            <Route
              path={`/studymaterial/:module/:institute/:course/:semester/:courseType`}
              element={<StudyMaterial1 />}
            />
            <Route path="/subjectmodules" element={<SubjectModules />} />
            <Route
              path="/previousyearquestion"
              element={<PreviousYearQuestion />}
            />
            <Route path="/Pdfviewer" element={<PdfViewer />} />

            <Route path="/study-material" element={<StudyMaterial />}>
              <Route path="ncert" element={<NCERT />} />
              <Route path="flowchart" element={<Flowchart />} />
              <Route path="short-question" element={<ShortQuestions />} />
              <Route path="long-question" element={<LongQuestions />} />
              <Route path="syllabus" element={<Syllabus />} />
              <Route path="study-notes" element={<StudyNotes />} />
              <Route path="solved-papers" element={<SolvedPapers />} />
              <Route
                path="important-questions"
                element={<ImportantQuestions />}
              />
              <Route
                path="previous-year-questions"
                element={<PreviousYearQuestions />}
              />
            </Route>
            <Route path="/boards" element={<Boards />}>
              <Route path="dibrugarh" element={<BoardDibrugarh />} />
              <Route path="ahsec" element={<BoardAHSEC />} />
              <Route path="cbse" element={<BoardCBSE />} />
              <Route path="guwahati" element={<BoardGuwahati />} />
            </Route>
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/career" element={<Career />} />
            <Route path="/contribute" element={<Contribute />} />
            <Route path="/earn-with-us" element={<NewEarnWith />} />
            <Route path="/current-affairs" element={<CurrentAffair />} />
            <Route path="/app-privacy-policy" element={<Privacy />} />
            <Route path="/new" element={<Newcomponent />} />
          </Routes>
          <Footer />
          <GoToTop />
        </ContextHeaderProvider>
      </Provider>
    </>
  );
};

export default App;
