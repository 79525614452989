import React, { useRef, useEffect, useState } from "react";
import "./Previous.css";
import { Link, useLocation } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import axios from "axios";
import qs from "qs";
const AHSEC = () => {
  const [insData, setInstData] = useState([]);
  const [courseName, setCourse] = useState("");
  const [semesters, setSemesters] = useState();
  const [singlesemester,setSingleSemester] = useState();
  const scollToRef1 = useRef();

  const scollToRef2 = useRef();
  const scollToRef3 = useRef();
  const scollToRef4 = useRef();
  const scollToRef5 = useRef();

  const location = useLocation();
  console.log(location.state.activeBtn);

  const getSingleSemester = async(data)=>{
    
    const data1 = {
      institute:location.state.activeBtn,
      course:courseName,
      semester:data
    } 

    console.log(data1,"this is data1");
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_coursetype.php`,
      qs.stringify(data1),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    console.log(response);

  }


  const getSemesters = async (coursekey) => {
    var data = {
      institute: location.state.activeBtn,
      course: coursekey,
    };
    setCourse(coursekey);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_semesters.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      data.semester = singlesemester;
      console.log(res.data);
      if (res.status === 200) {
        setSemesters(res.data);
        const data1 = {
          institute:location.state.activeBtn,
          course:coursekey,
          semester:singlesemester
        } 
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_coursetype.php`,
          qs.stringify(data1),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        console.log(response,"Course type");
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  // console.log(data);
  return (
    <>
      <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="ahsec_title">
                <h1>Dibrugarh University</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="first_main 12">
        <section className="first_year my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="first_year_info text-center">
                  <p>Select Your Course Here :</p>
                  <div className="button_div">
                    <div className="row">
                      {location.state.courseData.map((key, index) => {
                        return (
                          <div className="col-md-3 " key={index}>
                            <button
                              className="btn btn-primary mx-2"
                              onClick={() => {
                                getSemesters(key.course_title);
                                scollToRef1.current.scrollIntoView();
                              }}
                            >
                              <div className="button-inner">
                                {key.course_title}
                              </div>
                            </button>
                          </div>
                        );
                      })}
                      {/* <div className="col-md-3">
                      <button
                        className="btn btn-primary mx-2"
                        onClick={() => scollToRef1.current.scrollIntoView()}
                      >
                        <div className="button-inner">B.Com</div>
                      </button>
                    </div> */}
                      {/* <div className="col-md-2" onClick={()=>scollToRef2.current.scrollIntoView()}>
                      <button className="btn btn-primary">
                        <div className="button-inner">M.Com</div>
                      </button>
                    </div>
                    <div className="col-md-2" onClick={()=>scollToRef3.current.scrollIntoView()}>
                      <button className="btn btn-primary">
                        <div className="button-inner">BA</div>
                      </button>
                    </div>
                    <div className="col-md-2" onClick={()=>scollToRef4.current.scrollIntoView()}>
                      <button className="btn btn-primary">
                        <div className="button-inner">LLB</div>
                      </button>
                    </div>
                    <div className="col-md-3" onClick={()=>scollToRef5.current.scrollIntoView()}>
                      <button className="btn btn-primary">
                        <div className="button-inner">B.Sc</div>
                      </button>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className="divider" ref={scollToRef1}></div>
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">B.Com</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Previous Year Question Papers for B.Com (Both CBCS and
                    Regular)
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">
                      Bachelor of Commerce
                    </div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h2>Under CBCS System</h2>
            </div>
            <div className="row my-4">
              <div className="previous_pdf previous_one tabs_button d-flex">
                <div className="col-md-12">
                  <div
                    className="nav  nav-pills "
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {semesters ? semesters.map((item,key)=>{
                      console.log(item,"Item in semesters");
                      return(
                    <button
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                    onClick={()=>getSingleSemester(item.semester)}
                  >
                    <i className="fa-regular fa-folder me-3"></i> {item.semester}
                  </button>  
                    )}): <>
                    <h1>Please select course first</h1>
                    </>}
                    {/* <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 1
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 3
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 5
                    </button> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Business Statistics
                            </button>
                          </h1>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Human Resource Planning and Development
                            </button>
                          </h1>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Micro Credit Institutions
                            </button>
                          </h1>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <h1>Updating...</h1>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="true"
                              aria-controls="collapseFive"
                            >
                              International Banking
                            </button>
                          </h1>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1EGGfPVKNpshMEgCyKIRtg56eO_RTQc0b"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1jCzetUtCGRlOb76s2ncBPsdwQtXb9JWl"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1j6r6SrctL1gkD94a2Zg7c4cM_ri3v9a3"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Financial Services
                            </button>
                          </h1>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1dQA76rgnzB0KxpZmpPwPrTMLR2tBieML"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=14RiyKfqm8y8HfY-vopQ6nflfZtO4-_KQ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1YL-efVXiDzdAljaJyI3eelF7Pp-Ef-5V"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Cost and Management Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=12fwK9z7eNJzOe3uYiCrN1S0jnCy1QaNX"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1PLaJw4BceCmfrmsr43mt7XjMDTpZbRjY"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Auditing and Assurance
                            </button>
                          </h1>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1e8BtSMfCAm4p0Jv_kZjNjQp_eaZCevPZ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1MVPr94d6yN33-osvSj4MRM3e_Towb20D"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Indirect Taxation
                            </button>
                          </h1>
                          <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1TVOz5HW8RCIWCpirrdfoLEVJg77TRnd_"
                                >
                                  2015
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1t09T5Sd3XWLnvm9PXpxMsfPsIe9YYaOl"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Advanced Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1-AuUOTyFDr-gqdmJohCjJMfxYi3BQv6b"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                            >
                              Business Economics
                            </button>
                          </h1>
                          <div
                            id="collapse11"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1H20r15_iBEhxboqjkYCFFA3fYEr2uXnR"
                                >
                                  2015
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                NOTE: More CBCS Papers will be added soon
              </div>
            </button>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h2>Non - CBCS (Regular) System</h2>
            </div>
            <div className="row my-4">
              <div className="previous_pdf previous_one tabs_button d-flex">
                <div className="col-md-12">
                  <div
                    className="nav nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 1
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 3
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 5
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Business Statistics
                            </button>
                          </h1>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Human Resource Planning and Development
                            </button>
                          </h1>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Micro Credit Institutions
                            </button>
                          </h1>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <h1>Updating...</h1>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="true"
                              aria-controls="collapseFive"
                            >
                              International Banking
                            </button>
                          </h1>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1EGGfPVKNpshMEgCyKIRtg56eO_RTQc0b"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1jCzetUtCGRlOb76s2ncBPsdwQtXb9JWl"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1j6r6SrctL1gkD94a2Zg7c4cM_ri3v9a3"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Financial Services
                            </button>
                          </h1>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1dQA76rgnzB0KxpZmpPwPrTMLR2tBieML"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=14RiyKfqm8y8HfY-vopQ6nflfZtO4-_KQ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1YL-efVXiDzdAljaJyI3eelF7Pp-Ef-5V"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Cost and Management Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=12fwK9z7eNJzOe3uYiCrN1S0jnCy1QaNX"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1PLaJw4BceCmfrmsr43mt7XjMDTpZbRjY"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Auditing and Assurance
                            </button>
                          </h1>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1e8BtSMfCAm4p0Jv_kZjNjQp_eaZCevPZ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1MVPr94d6yN33-osvSj4MRM3e_Towb20D"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Indirect Taxation
                            </button>
                          </h1>
                          <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1TVOz5HW8RCIWCpirrdfoLEVJg77TRnd_"
                                >
                                  2015
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1t09T5Sd3XWLnvm9PXpxMsfPsIe9YYaOl"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Advanced Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1-AuUOTyFDr-gqdmJohCjJMfxYi3BQv6b"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                            >
                              Business Economics
                            </button>
                          </h1>
                          <div
                            id="collapse11"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1H20r15_iBEhxboqjkYCFFA3fYEr2uXnR"
                                >
                                  2015
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="syllabus_tabs text-center">
          <div class="headingblack text-center mb-2 pb-2">
            <h2>Syllabus for B.Com</h2>
          </div>
          <ul
            class="nav nav-pills mb-3 d-flex justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                CBCS Hons.
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                CBCS Non_Hons.
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Non-CBCS Regular
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-contact1-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact1"
                type="button"
                role="tab"
                aria-controls="pills-contact1"
                aria-selected="false"
              >
                Non-CBCS Distance
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <p>
                <span>
                  <Link
                    target="_blank"
                    to="https://drive.google.com/open?id=14hvh2V5fdO9r4EpP7j0t3KRIfmGrP75j"
                  >
                    Tap to Download B.Com CBCS Hons Syllabus for Dibrugarh
                    University
                  </Link>
                </span>
              </p>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <p>
                <Link
                  target="_blank"
                  to="https://drive.google.com/open?id=1r7Bpnq98-sHRHyewO6Zbl9Yi1iioUsYJ"
                >
                  <span>
                    Tap to Download B.Com CBCS Non-Hons Syllabus for Dibrugarh
                    University
                  </span>
                </Link>
              </p>
            </div>
            <div
              class="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <p>
                <Link
                  target="_blank"
                  to="https://drive.google.com/open?id=1r7Bpnq98-sHRHyewO6Zbl9Yi1iioUsYJ"
                >
                  <span>
                    Tap to Download B.Com CBCS Non-Hons Syllabus for Dibrugarh
                    University
                  </span>
                </Link>
              </p>
            </div>
            <div
              class="tab-pane fade"
              id="pills-contact1"
              role="tabpanel"
              aria-labelledby="pills-contact1-tab"
            >
              <p>
                <Link
                  target="_blank"
                  to="https://drive.google.com/open?id=1r7Bpnq98-sHRHyewO6Zbl9Yi1iioUsYJ"
                >
                  <span>
                    Tap to Download B.Com CBCS Non-Hons Syllabus for Dibrugarh
                    University
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2 B.Sc" ref={scollToRef5}>
          <div class="container">
            <div class="headingblack text-center my-2 py-2">
              <h1>Bachelor Of Science</h1>
              <h2>Previous Year Question Papers-B.Sc</h2>
            </div>
            <span className="typewriter_effect">
              <Typewriter
                words={[
                  "(Select Semester, then Subject and then tap on the year to download)",
                ]}
                loop={Infinity}
                cursor
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <div className="row my-4">
              <div className="previous_pdf previous_one tabs_button d-flex">
                <div className="col-md-12">
                  <div
                    className="nav nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 2
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 3
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 4
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Business Statistics
                            </button>
                          </h1>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Human Resource Planning and Development
                            </button>
                          </h1>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Micro Credit Institutions
                            </button>
                          </h1>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <h1>Updating...</h1>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="true"
                              aria-controls="collapseFive"
                            >
                              International Banking
                            </button>
                          </h1>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1EGGfPVKNpshMEgCyKIRtg56eO_RTQc0b"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1jCzetUtCGRlOb76s2ncBPsdwQtXb9JWl"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1j6r6SrctL1gkD94a2Zg7c4cM_ri3v9a3"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Financial Services
                            </button>
                          </h1>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1dQA76rgnzB0KxpZmpPwPrTMLR2tBieML"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=14RiyKfqm8y8HfY-vopQ6nflfZtO4-_KQ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1YL-efVXiDzdAljaJyI3eelF7Pp-Ef-5V"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Cost and Management Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=12fwK9z7eNJzOe3uYiCrN1S0jnCy1QaNX"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1PLaJw4BceCmfrmsr43mt7XjMDTpZbRjY"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Auditing and Assurance
                            </button>
                          </h1>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1e8BtSMfCAm4p0Jv_kZjNjQp_eaZCevPZ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1MVPr94d6yN33-osvSj4MRM3e_Towb20D"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Indirect Taxation
                            </button>
                          </h1>
                          <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1TVOz5HW8RCIWCpirrdfoLEVJg77TRnd_"
                                >
                                  2015
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1t09T5Sd3XWLnvm9PXpxMsfPsIe9YYaOl"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Advanced Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1-AuUOTyFDr-gqdmJohCjJMfxYi3BQv6b"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                            >
                              Business Economics
                            </button>
                          </h1>
                          <div
                            id="collapse11"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1H20r15_iBEhxboqjkYCFFA3fYEr2uXnR"
                                >
                                  2015
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2 B.A" ref={scollToRef3}>
          <div class="container">
            <div class="headingblack text-center my-2 py-2">
              <h1>Bachelor Of Arts</h1>
              <h2>Previous Year Question Papers-B.A</h2>
            </div>
            <span className="typewriter_effect">
              <Typewriter
                words={[
                  "(Select Semester, then Subject and then tap on the year to download)",
                ]}
                loop={Infinity}
                cursor
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <div className="row my-4">
              <div className="previous_pdf previous_one tabs_button d-flex">
                <div className="col-md-12">
                  <div
                    className="nav nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 2
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 3
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 4
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Business Statistics
                            </button>
                          </h1>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Human Resource Planning and Development
                            </button>
                          </h1>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Micro Credit Institutions
                            </button>
                          </h1>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <h1>Updating...</h1>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="true"
                              aria-controls="collapseFive"
                            >
                              International Banking
                            </button>
                          </h1>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1EGGfPVKNpshMEgCyKIRtg56eO_RTQc0b"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1jCzetUtCGRlOb76s2ncBPsdwQtXb9JWl"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1j6r6SrctL1gkD94a2Zg7c4cM_ri3v9a3"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Financial Services
                            </button>
                          </h1>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1dQA76rgnzB0KxpZmpPwPrTMLR2tBieML"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=14RiyKfqm8y8HfY-vopQ6nflfZtO4-_KQ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1YL-efVXiDzdAljaJyI3eelF7Pp-Ef-5V"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Cost and Management Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=12fwK9z7eNJzOe3uYiCrN1S0jnCy1QaNX"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1PLaJw4BceCmfrmsr43mt7XjMDTpZbRjY"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Auditing and Assurance
                            </button>
                          </h1>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1e8BtSMfCAm4p0Jv_kZjNjQp_eaZCevPZ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1MVPr94d6yN33-osvSj4MRM3e_Towb20D"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Indirect Taxation
                            </button>
                          </h1>
                          <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1TVOz5HW8RCIWCpirrdfoLEVJg77TRnd_"
                                >
                                  2015
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1t09T5Sd3XWLnvm9PXpxMsfPsIe9YYaOl"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Advanced Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1-AuUOTyFDr-gqdmJohCjJMfxYi3BQv6b"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                            >
                              Business Economics
                            </button>
                          </h1>
                          <div
                            id="collapse11"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1H20r15_iBEhxboqjkYCFFA3fYEr2uXnR"
                                >
                                  2015
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-2 my-2 LLB" ref={scollToRef4}>
          <div class="container">
            <div class="headingblack text-center my-2 py-2">
              <h1>Bachelor of Legislative Law</h1>
              <h2>Previous Year Question Papers-LLB</h2>
            </div>
            <span className="typewriter_effect">
              <Typewriter
                words={[
                  "(Select Semester, then Subject and then tap on the year to download)",
                ]}
                loop={Infinity}
                cursor
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <div className="row my-4">
              <div className="previous_pdf previous_one tabs_button d-flex">
                <div className="col-md-12">
                  <div
                    className="nav nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 2
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 3
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 4
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Business Statistics
                            </button>
                          </h1>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Human Resource Planning and Development
                            </button>
                          </h1>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Micro Credit Institutions
                            </button>
                          </h1>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <h1>Updating...</h1>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="true"
                              aria-controls="collapseFive"
                            >
                              International Banking
                            </button>
                          </h1>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1EGGfPVKNpshMEgCyKIRtg56eO_RTQc0b"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1jCzetUtCGRlOb76s2ncBPsdwQtXb9JWl"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1j6r6SrctL1gkD94a2Zg7c4cM_ri3v9a3"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Financial Services
                            </button>
                          </h1>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1dQA76rgnzB0KxpZmpPwPrTMLR2tBieML"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=14RiyKfqm8y8HfY-vopQ6nflfZtO4-_KQ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1YL-efVXiDzdAljaJyI3eelF7Pp-Ef-5V"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Cost and Management Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=12fwK9z7eNJzOe3uYiCrN1S0jnCy1QaNX"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1PLaJw4BceCmfrmsr43mt7XjMDTpZbRjY"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Auditing and Assurance
                            </button>
                          </h1>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1e8BtSMfCAm4p0Jv_kZjNjQp_eaZCevPZ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1MVPr94d6yN33-osvSj4MRM3e_Towb20D"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Indirect Taxation
                            </button>
                          </h1>
                          <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1TVOz5HW8RCIWCpirrdfoLEVJg77TRnd_"
                                >
                                  2015
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1t09T5Sd3XWLnvm9PXpxMsfPsIe9YYaOl"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Advanced Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1-AuUOTyFDr-gqdmJohCjJMfxYi3BQv6b"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                            >
                              Business Economics
                            </button>
                          </h1>
                          <div
                            id="collapse11"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1H20r15_iBEhxboqjkYCFFA3fYEr2uXnR"
                                >
                                  2015
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Faq first_year my-5" ref={scollToRef2}>
          <div className="container">
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">M.Com</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Previous Year Question Papers for M.Com
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">Master of Commerce</div>
                  </div>
                </h2>
              </div>
            </div>
            <div class="headingblack text-center my-2 py-2">
              <h1>Distance</h1>
            </div>
            <div className="row my-4">
              <div className="previous_pdf previous_one tabs_button d-flex">
                <div className="col-md-12">
                  <div
                    className="nav nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <button
                      className="nav-link active"
                      id="v-pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-home"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 2
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 3
                    </button>
                    <button
                      className="nav-link"
                      id="v-pills-messages-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-messages"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-messages"
                      aria-selected="false"
                    >
                      <i className="fa-regular fa-folder me-3"></i> Semster 4
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Business Statistics
                            </button>
                          </h1>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Human Resource Planning and Development
                            </button>
                          </h1>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              Micro Credit Institutions
                            </button>
                          </h1>
                          <div
                            id="collapseFour"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=11jV3uWGbEX5VeRHnBo8N2DsI7rLTYxgw"
                                >
                                  2012
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <h1>Updating...</h1>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="true"
                              aria-controls="collapseFive"
                            >
                              International Banking
                            </button>
                          </h1>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1EGGfPVKNpshMEgCyKIRtg56eO_RTQc0b"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1jCzetUtCGRlOb76s2ncBPsdwQtXb9JWl"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1j6r6SrctL1gkD94a2Zg7c4cM_ri3v9a3"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              Financial Services
                            </button>
                          </h1>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1dQA76rgnzB0KxpZmpPwPrTMLR2tBieML"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=14RiyKfqm8y8HfY-vopQ6nflfZtO4-_KQ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1YL-efVXiDzdAljaJyI3eelF7Pp-Ef-5V"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="false"
                              aria-controls="collapseSeven"
                            >
                              Cost and Management Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=12fwK9z7eNJzOe3uYiCrN1S0jnCy1QaNX"
                                >
                                  2014
                                </Link>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1PLaJw4BceCmfrmsr43mt7XjMDTpZbRjY"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Auditing and Assurance
                            </button>
                          </h1>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1e8BtSMfCAm4p0Jv_kZjNjQp_eaZCevPZ"
                                >
                                  2015{" "}
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1MVPr94d6yN33-osvSj4MRM3e_Towb20D"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseNine"
                              aria-expanded="false"
                              aria-controls="collapseNine"
                            >
                              Indirect Taxation
                            </button>
                          </h1>
                          <div
                            id="collapseNine"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1TVOz5HW8RCIWCpirrdfoLEVJg77TRnd_"
                                >
                                  2015
                                </Link>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1t09T5Sd3XWLnvm9PXpxMsfPsIe9YYaOl"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTen"
                              aria-expanded="false"
                              aria-controls="collapseTen"
                            >
                              Advanced Financial Accounting
                            </button>
                          </h1>
                          <div
                            id="collapseTen"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>{" "}
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1-AuUOTyFDr-gqdmJohCjJMfxYi3BQv6b"
                                >
                                  2018
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h1 className="accordion-header" id="headingFour">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="false"
                              aria-controls="collapse11"
                            >
                              Business Economics
                            </button>
                          </h1>
                          <div
                            id="collapse11"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <p>
                                <strong>Year :</strong>
                                <Link
                                  target="_blank"
                                  to="https://drive.google.com/open?id=1H20r15_iBEhxboqjkYCFFA3fYEr2uXnR"
                                >
                                  2015
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="college_intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="intro_inner">
                <div className="content">
                  <h2 className="my-2">About Dibrugarh University</h2>
                </div>
                <div className="content mt-5">
                  <p className="my-4">
                    Dibrugarh University is a university in the Indian state of
                    Assam. It was set up in 1965 under the provisions of the
                    Dibrugarh University Act, 1965,[1] enacted by the Assam
                    Legislative Assembly. It is a teaching-cum-affiliating
                    university.
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Location and Campus</h5>
                  <p>
                    The Dibrugarh University Campus is located at Rajabheta, 5
                    km from Dibrugarh Town (27° 29′ North Latitude and 94° 55′
                    East Longitude) and is spread over an area of 500 acres.[2]
                    The NH15 (old NH37) bisects the main campus from the other,
                    the latter primarily comprising teacher’s and officer’s
                    residence.
                  </p>
                  <p>
                    The Dibrugarh district is well known for its vast mineral
                    resources (including oil, natural gas and coal), flora and
                    fauna and many tea plantations. The diverse tribes with
                    their distinct dialects, customs, traditions and culture
                    make this area attractive to students of anthropology and
                    sociology, art and culture.
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Accreditation</h5>
                  <p>
                    It is accredited by the National Assessment and
                    Accreditation Council, with an ‘A’ Grade in 2017.[3] This
                    accreditation status is valid for a period of five years.[4]
                    Dibrugarh University is a member of the Association of
                    Indian Universities (AIU)[5] and the Association of
                    Commonwealth Universities (ACU).[6]
                  </p>
                  <p>Academics</p>
                  <p>Centre for Computer Studies</p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">
                    Administrative Office of the University
                  </h5>
                  <p>
                    The Centre for Computer Studies (CCS) originates in a
                    Computer Centre established in 1976, which taught a
                    “Six-month Certificate Course on Computer Programming”. In
                    2004 it was upgraded to the Centre for Computer Studies and
                    started “Post-Graduate Diploma in Computer Application
                    (PGDCA)”. BCA was introduced in July 2004 and MCA and B.Sc
                    IT in January and July 2007. The center has three computer
                    laboratories as well as its own library.
                  </p>
                  <p>
                    The MCA programme of CCS is approved by All India Council
                    for Technical Education (AICTE). It is also an accredited
                    study centre for Indira Gandhi National Open University.
                  </p>
                  <p>
                    Admission to CCS is based on a state level Common Admission
                    Test (CAT). Candidates are then called for a personal
                    interview, counseling and admission. In addition, some
                    programmes have requirements for passing 10+2 level with
                    mathematics and/or statistics at some threshold mark in
                    aggregate.
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">
                    Dibrugarh University Institute of Engineering and Technology
                  </h5>
                  <p>
                    The Dibrugarh University Institute of Engineering and
                    Technology (DUIET) was established in 2009 as a constituent
                    institute and an integral part the university. The institute
                    was established with due approval from the All India Council
                    for Technical Education (AICTE), New Delhi and necessary
                    permission from the Assam state government.
                  </p>
                  <p>
                    The first session of the institute commenced in August 2009
                    with an intake capacity of 60 in each of the following
                    disciplines to a B. Tech. degree:
                  </p>
                  <ul>
                    <li>Electronics and Communication Engineering</li>
                    <li>Computer Science and Engineering</li>
                    <li>Petroleum Engineering</li>
                    <li>Mechanical Engineering</li>
                    <li>
                      DUIET is located in the Dibrugarh University campus. It
                      has excellent infrastructure and facilities. The
                      administration of DUIET is headed by Dr. M.C.Bora who is
                      the institute director.
                    </li>
                  </ul>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Centre for Management Studies</h5>
                  <p>
                    The Centre for Management Studies (CMSDU) is a management
                    school that is part of Dibrugarh University. It started
                    functioning from 3 February 2003, with the first batch of
                    Master of Business Administration (MBA) students enrolled
                    into the two-year programme.[citation needed] The three-year
                    Bachelor of Business Administration (BBA) and the three-year
                    MBA (part-time) classes started in 2004.[citation needed]
                    2006 saw the launch of the one-year PGDTM (Post Graduate
                    Diploma in Tourism Management) Programme.[citation needed]
                    It is also running a Ph.D. programme which can be pursued
                    both part-time as well as full-time.
                  </p>
                </div>
                <div className="content mt-3">
                  <h5 className="my-2">Centre for Juridical Studies</h5>
                  <p>
                    The centre for juridical studies is a Centre of Dibrugarh
                    University that imparts both undergraduate and Post Graduate
                    legal education. The centre was established in the year
                    2006. The Centre runs BALL.B (H)and LL.M (Constitutional
                    Law, Corporate Law and Criminal Law Groups) courses.[7]
                  </p>
                  <p>
                    Eligibility: For B.A.LL.B. (H): A candidate for admission to
                    the Five- year B.A.LL.B. (Hons) Course shall have passed the
                    Higher Secondary Examination (10+2) or an equivalent
                    examination securing at least 45% in aggregate of the total
                    marks. Provided that there shall be a relaxation of 5% marks
                    for SC/ST/OBC candidates. For LL.M.: A candidate to take
                    admission into LL.M. Course must have 50% marks in aggregate
                    in 3 year LL.B. or 5 year B.A.LL.B. (H) Course recognized by
                    University Grants Commission (UGC) and Bar Council of India
                    (BCI). The candidates appearing in final year examination of
                    the aforesaid Programmes may also apply but at the time of
                    admission they must produce the mark sheet and pass
                    Certificate in original.
                  </p>
                  <p>
                    Admission Procedure: The admission to the aforesaid
                    Programmes will be made only on merit basis through an
                    Admission Test and (viva-voce/wherever applicable) to be
                    conducted by the University. Admission cannot be claimed by
                    any applicant as a matter of right. Note: In case of LL.M
                    only, on being selected for admission, the applicant has to
                    appear before admission committee at the Centre for
                    Juridical Studies, Dibrugarh University with all original
                    certificates, mark sheets and other requirements on
                    scheduled date and time. If any selected candidate is unable
                    to appear personally, he may send his authorized
                    representative with the required documents. If any applicant
                    fails to deposit prescribed fee within the stipulated time,
                    his/her admission will automatically stand cancelled. If any
                    selected candidate or his authorised representative does not
                    appear before Admission Committee on scheduled date and
                    time, his/her candidature shall be deemed to be cancelled
                    without any intimation in writing.[7]
                  </p>
                  <p>
                    Dibrugarh University was ranked in the 101–150 band overall
                    in India by the National Institutional Ranking Framework
                    (NIRF) in 2019,[12] 86 among universities[13] and 35 in the
                    pharmacy ranking.[11]
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="ahsec_banner">
        <div className="container h-100">
          <div className="row h-100">
            <div
              className="col-md-12 h-100"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="ahsec_title">
                <p>Previous Year Question Papers</p>
                <h1>AHSEC</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="first_main">
        <section className="first_year my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="first_year_info text-center"
                  data-aos="fade-right"
                  data-aos-duration="3000"
                >
                  <p>Select Your Class/Standard Here :</p>
                  <div className="button_div">
                    <button className="btn btn-primary mx-2" onClick={()=>scollToRef1.current.scrollIntoView()}>
                      <div className="button-inner">Class 11 (First year)</div>
                    </button>
                    <button className="btn btn-primary" onClick={()=>scollToRef2.current.scrollIntoView()}>
                      <div className="button-inner">Class 12 (Second year)</div>
                    </button>
                  </div>
                </div>
                <div className="divider" ref={scollToRef1}></div>
              </div>
            </div>
            <div className="row"  data-aos="fade-left"
              data-aos-duration="3000">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">Class 11</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Previous Year Question Papers for Assam Higher Secondary
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">H.S. First Year</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>commerce</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Banking
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        Business Studies
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                      >
                        Economics
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                      >
                        English
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                      >
                        Alternative English
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need Solutions to these papers?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
        </section>
      </div>
      <div className="second_main" ref={scollToRef2}>
        <section className="first_year">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="divider"></div>
              </div>
            </div>
            <div className="row">
              <div className="first_year_acc text-center d-block">
                <div className="advanced-heading-content">
                  <div class="">Class 12</div>
                </div>
                <div className="sub-heading">
                  <div className="sub-heading-content">
                    Previous Year Question Papers for Assam Higher Secondary
                  </div>
                </div>
                <h2 className="heading-title">
                  <div className="main-heading">
                    <div className="main-heading-inner">H.S. Second Year</div>
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section class="Faq py-1 my-1">
          <div class="container">
            <div class="headingblack text-center mb-2 pb-2">
              <h5>commerce</h5>
            </div>
            <div class="row">
              <div class="">
                <div class="accordion" id="myAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Accountancy
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        Banking
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        Business Studies
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                      >
                        Economics
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                      >
                        English
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                      <button
                        type="button"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                      >
                        Alternative English
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      class="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div class="card-body">
                        <p className="text-center">
                          <span>Tap on the Year:</span>
                          <span>
                            <Link>2015</Link>
                            <Link>2016</Link>
                            <Link>2017</Link>
                            <Link>2017</Link>
                            <Link>2018</Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button_div text-center bottom_div_button my-3">
            <button className="btn btn-primary mx-2">
              <div className="button-inner">
                Need Solutions to these papers?
                <i class="fas fa-long-arrow-right mx-2"></i> Click Here
              </div>
            </button>
          </div>
          <div className="divider"></div>
        </section>
      </div>
      <section className="college_intro">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="intro_inner">
                <div className="content">
                  <h2 className="my-2">
                    About Assam Higher Secondary Education Council (AHSEC)
                  </h2>
                  <p className="my-4">
                    AHSEC an acronym for Assam Higher Secondary Education
                    Council is a board of education under the&nbsp;state
                    government of Assam. It is the regulating body for higher
                    secondary (10+2 stage) education in the state of
                    Assam.&nbsp; The Assam Higher Secondary Education Council
                    was established by the Assam government on 1 June 1984 to
                    regulate, supervise and develop the system of higher
                    secondary education in the State of Assam.
                  </p>
                </div>
                <div className="content mt-5">
                  <h5 className="my-2">
                    Functions Of Assam Higher Secondary Education Council
                  </h5>
                  <p className="my-4">
                    One of the main operations of the AHSEC (Assam Higher
                    Secondary Education Council) is to enforce the rules and
                    regulations, that have been set for the schools that are
                    affiliated with the Assam Board. Other functions also
                    include prescribing the syllabus, devising courses of study
                    like the textbooks, conducting exams and so on.
                  </p>
                </div>
                <div className="content mt-5">
                  <h5 className="my-2">
                    Exam Conducted by Assam Higher Secondary Education Council
                  </h5>
                  <p className="my-4">
                    The Assam Higher Secondary Education Council conducts Class
                    12 exam every year.&nbsp;AHSEC or the Assam Higher Secondary
                    Education Council supervises, regulates, and develops the
                    system of 12th standard in the state. The 12th standard
                    exams in Assam are conducted for Science, Commerce, Arts and
                    Vocational Groups.
                  </p>
                </div>
                <div className="content mt-5">
                  <h5 className="my-2">About Previous Year Question Papers</h5>
                  <p className="my-3">
                    As of 2014, the Assam Higher Secondary Education Council has
                    adopted the NCERT curriculum for class 12 students.
                    Therefore the exams are also held in the NCERT pattern. The
                    board has further stated that by adopting such curriculum it
                    will bring greater benefit to the students as they will be
                    on par with students from other boards. However, class 12
                    exams are very important for students as the results they
                    obtain will determine the careers they choose in the future.
                  </p>
                  <p className="my-3 pe-4">
                    Therefore, students need to prepare well for the exam. Apart
                    from studying from the&nbsp;Assam Board textbooks, another
                    way student can study productively is by practising and
                    solving question papers. Students can further use and solve
                    question papers to know how the entire exam paper will be
                    set. Additionally, by making use of question papers,
                    students will;
                  </p>
                  <ul className="my-3">
                    <li>
                      Understand clearly how the exam paper pattern will be.
                    </li>
                    <li>
                      Know the repeated topics and what to focus on while
                      studying.
                    </li>
                    <li>Learn about the marking scheme.</li>
                    <li>Figure out the time needed to solve each question.</li>
                  </ul>
                  <p>
                    Students can access a set of Assam board class 12 question
                    papers below and refer them to test their preparation level
                    as well get an idea about the types of questions that are
                    asked in each subject. These question papers will serve a
                    great purpose of helping students prepare well for the board
                    exams.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default AHSEC;