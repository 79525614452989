import React, { useState, useEffect } from "react";
import "./Modals.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AdsComponent from "../../AdsComponent/AdsComponent";

export const AdsModalForPrevious = ({
  show,
  handleModal,
  adsval,
  state,
  fornavigate,
  close,
}) => {
  const navigate = useNavigate();
  const [timeleft, setTimeleft] = useState(5);

  useEffect(() => {
    if (timeleft === 0) {
      setTimeleft(null);
      handleModal();
      if (fornavigate === "From-pdf-link") {
        navigate("/Pdfviewer", {
          state: {
            institute: state?.institute,
            studymaterialname: state.studymaterialname,
            modulepdf: state.modulepdf,
            semestername: state.semestername,
            subject: state.subject,
            course: state.course,
          },
        });
      }
    }

    if (!timeleft) return;

    const intervalId = setInterval(() => {
      setTimeleft(timeleft - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeleft]);

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  console.log(show, adsval, "add value")
  return (
    <>
      {show && adsval == "1" && (
        <>
          <div className="modalContainer">
            <div
              className="modal_show"

              onClick={(e) => e.stopPropagation()}
            >
              <div style={{
                position: 'relative',
                margin: '20px 0 0 0',
                height: '200px',
                textAlign: 'center',
                width: "100%",            // Full-width
                height: "400px",          // Height of the banner
                backgroundColor: "#f0f0f0", // Light grey background for the placeholder
                display: "flex",          // Use flexbox to center content
                justifyContent: "center", // Center horizontally
                alignItems: "center",     // Center vertically
                fontSize: "30px",         // Placeholder text size
                color: "#666",            // Text color
                border: "2px dashed #ccc", // Dashed border for placeholder effect
              }} className=""> Ad
                <AdsComponent dataAdSlot="2597424317" />
              </div>
              <div style={{
                margin: '0 0 20px 0',
                width: "100%",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#f8f8f8",
                fontSize: "18px",
              }}>
                <button
                  className="modal-close"
                  style={{ color: "black" }}
                  onClick={() => close()}
                >
                  Close
                </button>
                <div>

                  {timeleft != null && (
                    <h3 style={{ color: "black" }}>
                      00:{timeleft < 10 ? "0" + timeleft : timeleft}
                    </h3>
                  )}

                </div>
              </div>

            </div>
          </div>
          <div className="modal_overlay" onClick={handleOverlayClick} />
        </>
      )}
    </>
  );
};
