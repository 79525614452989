import React, { useState, useEffect } from "react";
import "./Modals.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import AdsComponent from "../../AdsComponent/AdsComponent";

const Modals = ({
  show,
  close,
  institute,
  StudyMaterial,
  addval,
  InstituteAdsVal,
  StudyMaterialAdsVal,
  comesFrom,
  InstituteId,
  timerData,
}) => {
  const study = useSelector((state) => state?.study);
  const navigate = useNavigate();
  const [timeleft, setTimeleft] = useState(parseInt(timerData?.timing));

  const redirectedToParticularPage = () => {
    let data = { institute: institute, InstituteId: InstituteId };
    if (institute.length) {
      if (comesFrom === "institute") {
        // let splitData = institute.split(" ");
        // let removeSpaceInInstitute = institute.replaceAll(" ", "-");
        navigate(`/${data?.institute}/${InstituteId}`, { state: data });
      } else
        navigate(`/studymaterial/${study.module_name}`, { state: institute });
    }
  };

  useEffect(() => {
    if (timeleft === 0) {
      setTimeleft(null);
      close();
      redirectedToParticularPage();
    }

    if (!timeleft) return;

    const intervalId = setInterval(() => {
      setTimeleft(timeleft - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeleft]);

  useEffect(() => {
    if (
      (InstituteAdsVal === "0" && comesFrom === "institute") ||
      addval === "0"
    ) {
      return redirectedToParticularPage();
    } else if (
      (StudyMaterialAdsVal === "0" && comesFrom === "studyMaterial") ||
      addval === "0"
    ) {
      return redirectedToParticularPage();
    }
  }, []);

  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };
  console.log(show, StudyMaterialAdsVal, "add value")

  return (
    <>
      {/* <Helmet>
     <title></title>
     </Helmet> */}

      {StudyMaterialAdsVal === "1" && comesFrom === "studyMaterial"
        ? show && (
          <>
            <div className="modalContainer">
              <div
                className="modal_show"

                onClick={(e) => e.stopPropagation()}
              >
                <div style={{
                  position: 'relative',
                  margin: '20px 0 0 0',
                  height: '200px',
                  textAlign: 'center',
                  width: "100%",            // Full-width
                  height: "400px",          // Height of the banner
                  backgroundColor: "#f0f0f0", // Light grey background for the placeholder
                  display: "flex",          // Use flexbox to center content
                  justifyContent: "center", // Center horizontally
                  alignItems: "center",     // Center vertically
                  fontSize: "30px",         // Placeholder text size
                  color: "#666",            // Text color
                  border: "2px dashed #ccc", // Dashed border for placeholder effect
                }} className=""> Ad
                  <AdsComponent dataAdSlot="2597424317" />
                </div>
                <div style={{
                  margin: '0 0 20px 0',
                  width: "100%",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#f8f8f8",
                  fontSize: "18px",
                }}>
                  <button className="modal-close" onClick={() => close()}>
                    Close
                  </button>
                  <div>

                    {timeleft != null && (
                      <h3 style={{ color: "black" }}>
                        00:{timeleft < 10 ? "0" + timeleft : timeleft}
                      </h3>
                    )}

                  </div>
                </div>

              </div>
            </div>

            <div className="modal_overlay" onClick={handleOverlayClick} />
          </>
        )
        : InstituteAdsVal === "1" && comesFrom === "institute"
          ? show && (
            <>
              <div className="modalContainer">
                <div
                  className="modal_show"

                  onClick={(e) => e.stopPropagation()}
                >
                  <div style={{
                    position: 'relative',
                    margin: '20px 0 0 0',
                    height: '200px',
                    textAlign: 'center',
                    width: "100%",            // Full-width
                    height: "400px",          // Height of the banner
                    backgroundColor: "#f0f0f0", // Light grey background for the placeholder
                    display: "flex",          // Use flexbox to center content
                    justifyContent: "center", // Center horizontally
                    alignItems: "center",     // Center vertically
                    fontSize: "30px",         // Placeholder text size
                    color: "#666",            // Text color
                    border: "2px dashed #ccc", // Dashed border for placeholder effect
                  }} className=""> Ad
                    <AdsComponent dataAdSlot="2597424317" />
                  </div>
                  <div style={{
                    margin: '0 0 20px 0',
                    width: "100%",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#f8f8f8",
                    fontSize: "18px",
                  }}>
                    <button className="modal-close" onClick={() => close()}>
                      Close
                    </button>
                    <div>

                      {timeleft != null && (
                        <h3 style={{ color: "black" }}>
                          00:{timeleft < 10 ? "0" + timeleft : timeleft}
                        </h3>
                      )}

                    </div>
                  </div>

                </div>
              </div>
              <div className="modal_overlay" onClick={handleOverlayClick} />
            </>
          )
          : null}
    </>
  );
};

export default Modals;
