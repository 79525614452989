import React from "react";
// import "./StudyMaterial.css";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import pdf1 from "../../assets/pdf/Syllabus-12.pdf";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import PdfDemo1 from "../Previous/PdfDemo1";
import { useState, useEffect } from "react";
import { BsArrowDownShort } from "react-icons/bs";
import { useRef } from "react";
import qs from "qs";
import axios from "axios";
import HeaderBanner from "../Previous/HeaderBanner";
import googleplay from "../../assets/images/google-play-badge.png";
import Playstore from "../../assets/images/Playstore.gif";
import "../Study material/StudyMaterial.css";
import Lottie from "react-lottie";
import animation from "../../Lotties/download.json";
import ShareWith from "../ShareWith";
import animation1 from "../../Lotties/Playstore.json";
import animation2 from "../../Lotties/PlayStore2.json";
// import styles from '../Study material/PdfViewer.module.css'
import styles from "../Previous/PdfForinstitute.module.css";
import downloadgif from "../Study material/download.gif"
import { IoClose } from "react-icons/io5";
import { IoMdCloudDownload } from "react-icons/io";
import { Helmet } from "react-helmet";
import styles1 from "../Study material/PdfViewer.module.css"
import { useSelector } from "react-redux";
const PdfForinstitute = () => {
  const scrollto = useRef();
  const clickbtn = useRef();
  const downloadref = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const [pdfurl, setPdfUrl] = useState();
  const [button, setButton] = useState();
  const [active, setActive] = useState();
  const [embedurl, setEmberdurl] = useState();
  const [isVisible, setVisibleTure] = useState(false);
  const [breakpoint, setBreakpoint] = useState(false);
  const [buttonvisible, setButtonVisible] = useState(false);
  const [timeleft, setTimeLeft] = useState(3);
  const [isDataComing, setDataComing] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [popUp, setPopUp] = useState(false)
  const { institute, courses, sem, courseType, subject, modulee, pdf } = useParams()
  const updatedSem = sem ? sem.replaceAll("*", "/") : sem

  // ----------------------redux--------------------------------------------------------------------
  const { instituteName, ModuleName, semesterName, subjectName, courseName, eBook } = useSelector((state) => state?.study)

  const defaultOptions = {
    loop: false,
    autoplay: { isloading },
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animation1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions12 = {
    loop: true,
    autoplay: true,
    animationData: animation2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const iconStyle = {
    height: "260px",
    width: "auto"
  };


  useEffect(() => {
    setButton("On-This");
    setActive("On-This");
    getPdfToDownload();
    // setEmberdurl(pdfurl);
    setButton("On-This");
    setActive("On-This");
    window.scroll(0, 0);
    clickbtn?.current?.click();
  }, []);

  useEffect(() => {
    if (isDataComing) {
      getPdfToDownload();
      // setEmberdurl(pdfurl);
    }
  }, [isDataComing]);

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getPdfToDownload = async () => {
    const updatedSemester = sem?.replaceAll("*", "/")
    const data = {
      institute: instituteName,
      course: courseName,
      semester: semesterName,
      coursetype: eBook,
      subject: subjectName,
      module: ModuleName,
    };

    try {
      setPageLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_ebook_content.php`,
        qs.stringify(data),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200 && response.data.length > 0) {
        console.log("study data", response?.data)
        setPdfUrl(response.data[0].url);
        setPageLoading(false)
      } else {
        setPdfUrl("");
        setPageLoading(false)
      }
    } catch (err) {
      console.log(err);
      setPdfUrl("");
      setPageLoading(false)
    }
  };

  const handleScroll = () => {
    const scrollamount = window.pageYOffset;
    if (scrollamount >= 299) {
      setVisibleTure(true);
    } else {
      setVisibleTure(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const downloadFunction = () => {
    downloadref.current?.click();

    setIsLoading(false);
  };

  return pageLoading ? <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", width: "100vw" }}>  <div class="loader-container">
    <div class="loader"></div>
  </div></div> : (
    <>
      {institute && courses && sem && courseType && subject && modulee && pdf && (
        <Helmet>
          <title>{`${institute}/${courses}/${sem}/${courseType}/${subject}/${modulee}/${pdf}`}</title>
          <meta
            name="description"
            content={`${institute} ${courses} ${sem} ${courseType} ${subject} ${modulee} ${pdf}`}
          />
        </Helmet>
      )}
      <section className="ahsec_banner1">
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-7">
              <div className="headings-header">
                <div className="top-heading">
                  <div className="d-flex">
                    <p className="text-light">
                      <span className="d-flex justify-content-center gap-1 align-items-center">
                        {/* <i class="fa-regular fa-building"></i> */}
                        {/* {location?.state?.course} */}
                      </span>
                    </p>
                    <p className="text-light mx-4">
                      <span className="d-flex justify-content-center gap-1 align-items-center">
                        {/* <i aria-hidden="true" class="fas fa-feather-alt"></i> */}
                        {/* {location?.state?.institute} */}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="ahsec1-heding">
                  <h2 className="text-light">

                    {" "}
                    {pdf} - {modulee} - {subject} {courseType}
                    {updatedSem} - {courses} - {institute}
                    {/* {location?.state?.bookname}- {location?.state?.course} |{" "}
                    {location?.state?.semester} - | {location?.state?.institute} */}
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </section>

      {breakpoint && isVisible && (
        <HeaderBanner
          className={`header-banner `}
          state={{
            bookname: location.state?.bookname,
            course: location?.state?.course,
            semester: location.state.semester,
            institute: location?.state?.institute,
          }}
        />
      )}
      <section className="pdf-section">
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="pdf-box">
                <div className="pdf_1">
                  <PdfDemo1 file={pdfurl} />
                </div>

                <div className="ads-here"></div>
                <div ref={scrollto} className="button-div">
                  <div className="outer-btn-div">
                    <button
                      className={`left-btn ${active === "On-This" ? "activebtn1" : ""
                        }`}
                      onClick={() => {
                        setButton("On-This");
                        setActive("On-This");
                      }}
                    >
                      On This?
                      <div className="effect-click"></div>
                    </button>
                    <button
                      className={`right-btn ${active === "Get-Everything" ? "activebtn" : ""
                        }`}
                      onClick={() => {
                        setButton("Get-Everything");
                        setActive("Get-Everything");

                        setIsLoading(false);
                      }}
                    >
                      Get Everything!!!
                      <div className="effect-click1"></div>
                    </button>
                  </div>
                </div>
                <div className="button-content">
                  {button === "On-This" && (
                    <div className="button-div2">
                      <a
                        ref={clickbtn}
                        className="d-flex justify-content-between align-items-center gap-1"
                      >
                        {/* <button
                          className="Download-text"
                          onClick={() => {
                            setIsLoading(true);
                            setTimeout(downloadFunction, 6000);
                          }}
                        >
                          Tap To Download
                        </button> */}
                        <button
                          className="learn-more"
                          onClick={() => {
                            setIsLoading(true);
                            setTimeout(downloadFunction, 6000);
                          }}
                        >
                          <span class="circle" aria-hidden="true">
                            <span class="icon arrow"></span>
                          </span>
                          <span class="button-text">Tap to download</span>
                        </button>
                        <div className="">
                          {isloading ? (
                            <Lottie
                              options={defaultOptions}
                              height={100}
                              width={100}
                            // style={{background:"gray", stroke:"blue" }}
                            />
                          ) : (
                            <div className="circle-download">
                              <BsArrowDownShort className="icon-download" />
                            </div>
                          )}
                        </div>
                      </a>
                      <a
                        className="d-none "
                        ref={downloadref}
                        href={pdfurl}
                        target="_blank"
                      ></a>
                    </div>
                  )}
                  {button === "Get-Everything" && (
                    <>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.rollingnotes&pli=1"
                        target="_blank"
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-md-3">

                            </div>
                            <div className="col-md-6">
                              <div className="h-100 d-flex justify-content-center align-items-center">
                                <a
                                  href="https://play.google.com/store/apps/details?id=com.rollingnotes&pli=1"
                                  target="_blank"
                                >
                                  <div className="h-100 d-flex justify-content-center align-items-center">
                                    <Lottie
                                      options={defaultOptions1}
                                      style={iconStyle}
                                    />
                                    <img src={Playstore} alt="" srcset="" />
                                  </div>
                                  {/* <div className="text-center d-flex flex-column">
                                    <div className="Want-More">
                                      Want More ???
                                    </div>
                                    <div className="d-flex justify-content-center">
                                      <div className="Want-More1">
                                        Download Our
                                      </div>
                                      <div className="Want-More2 ml-1">
                                        100% Free App
                                      </div>
                                    </div>
                                    <button className="mt-3 google-button">
                                      <img
                                        height={30}
                                        width={120}
                                        src={googleplay}
                                        alt=""
                                      />
                                    </button>
                                  </div> */}
                                </a>
                              </div>
                            </div>
                            {/* <div className="col-md-3">
                              <div className="h-100 d-flex justify-content-center align-items-center">
                                <Lottie
                                  options={defaultOptions12}
                                  style={iconStyle}
                                />
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="col-md-1">
              <>
                <div
                  onClick={() => {
                    scrollto.current.scrollIntoView();
                    setButton("On-This");
                    setActive("On-This");
                    setIsLoading(false);
                  }}
                  class="eael-creative-button-wrapper"
                  style={{ cursor: "pointer" }}
                >
                  <a
                    class="eael-creative-button eael-creative-button--wave"
                    // href="#download"
                    data-text="Go!"
                  >
                    <div class="creative-button-inner">
                      <span class="eael-creative-button-icon-left">
                        <i
                          aria-hidden="true"
                          class="fas fa-cloud-download-alt"
                        ></i>
                      </span>
                      <span class="cretive-button-text">Download</span>
                    </div>
                  </a>
                </div>
              </>
            </div> */}

            {/* new download  button added  */}


            <div className="col-md-1">
              <>
                <div
                  className={`${styles1.popUp} ${popUp ? styles1.move1 : styles1.unMove
                    }`}
                >
                  <div
                    onClick={() => {
                      setPopUp(!popUp);
                    }}
                    className={`${styles1.closeButton}`}
                  >
                    <IoClose />
                  </div>
                  <div
                    onClick={() => {
                      scrollto.current.scrollIntoView();
                      setButton("On-This");
                      setActive("On-This");
                      setPopUp(!popUp);

                      setIsLoading(false);
                    }}
                    className="text-center"
                  >
                    <div style={{ fontFamily: "Prompt" }}>
                      Click Here to download{" "}
                    </div>
                    <div className="d-flex justify-content-start">
                      <div className="border ">
                        {/* <img src={download} alt="my-gif" style={{height:"128px",width:"128px"}} /> */}

                        <button
                          className={`${styles1.button1} ${styles1.bg}    `}
                        >
                          Download
                        </button>
                      </div>
                      {/* <div className="border w-50">
                 
                       <img src={point} alt="my-gif" style={{height:"128px",width:"128px"}} />
                  
                         </div> */}
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => {
                    setPopUp(!popUp);
                  }}
                  class={`${styles1.downloadButton}`}
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ fontFamily: "Prompt" }}>
                    {" "}
                    <IoMdCloudDownload /> Download
                  </span>
                </div>
              </>
            </div>




          </div>
        </div>
      </section>

      <section>

        <ShareWith pdf={pdfurl} />
      </section>
    </>
  );
};

export default PdfForinstitute;
