import React, { useState, useEffect, useTransition } from "react";
import { useForm } from "react-hook-form";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import { resolvePath, useNavigate } from "react-router-dom";
import Banner from "./Banner/Banner";
import "./Home.css";
import Testimonial from "./Testimonial/Testimonial";
import AccordionCard from "../../components/AccordionCard/AccordionCard";
import Modals from "../../components/Modals/Modals";
import { HomeTabs } from "../../utils/HomeTabs";
import axios from "axios";
import AppContext from "../../feature/ContextForHeader/ContextForheader";
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qs from "qs";
import { useDispatch, useSelector } from "react-redux";
import { isAddvisible } from "../../feature/globalAdsSlice";
import { data } from "jquery";
import { fetchTimerVal } from "../../feature/actions/timerActions";
import { Helmet } from "react-helmet";
import { getModule } from "../../feature/slices/StudyMaterialslice";



const Home = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { stateforhometabs, setStateForHomeTabs } = useContext(AppContext);

  const [instData, setInstData] = useState([]);
  const [studyData, setStudyData] = useState([]);
  const [studymaterial, setStudyMaterial] = useState();
  const [institutename, setInstituteName] = useState();
  const [InstituteAdsVal, setInstituteAdsVal] = useState();
  const [StudyMaterialAdsVal, setStudyMaterialAdsVal] = useState();
  const [addVal, setAddVal] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [basicActive, setBasicActive] = useState("tab1");

  const [comesFrom, setComesFrom] = useState("");
  const [InstituteId, setInstituteId] = useState("")
  const [activetab, setActiveTab] = useState();
  const [subscriberData, setSubscriberData] = useState();
  const [isPending, startTransition] = useTransition()


  const disptach = useDispatch()

  const { timerData } = useSelector((state) => state.timers)

  useEffect(() => {
    disptach(fetchTimerVal())
  }, [])


  //using useEffect to fetch the institutes from the backend
  useEffect(() => {
    instFetch();
    adsFun();
    setBasicActive("tab1");
    studyFetch()
  }, []);

  useEffect(() => {
    if (stateforhometabs === "tab1") {
      setBasicActive(stateforhometabs);
    } else if (stateforhometabs === "tab2") {
      setBasicActive(stateforhometabs);
    }
  }, [stateforhometabs]);

  //Courses relates to specific institute are stored here

  function adsVisible(data) {

    disptach(isAddvisible(data))
  }

  const adsFun = async () => {
    try {
      const response = await axios.get(
        "https://rollingnotes.in/app/backend/api_v2/ads_setting.php"
      );
      if (response.status === 200) {
        setAddVal(response?.data?.ads);
        setInstituteAdsVal(response?.data?.home?.institute);
        setStudyMaterialAdsVal(response?.data.home?.studymaterial);
        adsVisible(response?.data?.ads);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //fetching institute function
  const instFetch = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_institutes.php`
      );
      setInstData(response?.data);
    } catch (error) {
      console.log("error:::: ", error);
    }
  };


  // ------fetching study materials
  const studyFetch = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_URL}/app/backend/api_v2/fetch_study_materials.php`
      );
      setStudyData(response?.data);
    } catch (error) {
      console.log("error:::: ", error);
    }
  };
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
    if (value) {
      setStateForHomeTabs(value);
    }
    // } else if (value === "tab2") {
    //   localStorage.setItem("ActiveItem", JSON.stringify(value));
    // }
  };

  //this modal is used to redirect user to the selected university
  const handleModal = (instituteName, comesFrom, id) => {
    setShowModal(!showModal);
    setInstituteName(instituteName);
    setComesFrom(comesFrom);
    setInstituteId(id)
  };

  const handleChange = (e) => {
    e.preventDefault();

    setSubscriberData({ ...subscriberData, [e.target.name]: e.target.value });
  };

  const handleSubscriberFun = async (data) => {

    try {

      let obj = JSON.stringify(data);
      let response = await axios.post(
        "https://app.rollingnotes.in/backend/add_subscriber.php",
        obj,

      );
      if (response?.status === 200) {

        toast?.success("successfully subscribed");
        reset()

      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <Banner />
      <Helmet>
        <title>Rolling Notes</title>
        <meta name="description" content="RollingNotes" />
      </Helmet>

      {/* Tabs Section Starts Here */}
      <section className="svg-section">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
          <path
            className="shape-fill"
            opacity="0.33"
            d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
          ></path>
          <path
            className="shape-fill"
            opacity="0.66"
            d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
          ></path>
          <path
            className="shape-fill"
            d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
          ></path>
        </svg>
      </section>

      {/* Tabs Section */}
      <section className="tabs-section py-5">
        <div className="container">
          <div className="tabs-wrapper">
            <MDBTabs className="tabs-btns-wrapper d-flex flex-column flex-md-row">
              <MDBTabsItem>
                <MDBTabsLink
                  className="tab-btn"
                  onClick={() => handleBasicClick("tab1")}
                  active={basicActive === "tab1"}
                >
                  <div className="tab-btn-content">
                    <i className="fa-regular fa-building"></i> Institutes
                  </div>
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink
                  className="tab-btn"
                  onClick={() => handleBasicClick("tab2")}
                  active={basicActive === "tab2"}
                >
                  <div className="tab-btn-content">
                    <i className="fa-solid fa-book"></i> Study Materials
                  </div>
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
            <MDBTabsContent>
              <MDBTabsPane show={basicActive === "tab1"}>
                <h6 className="mt-4 mt-md-0">Select Your Institute Below</h6>
                <div className="tabs-content">
                  {Array.isArray(instData) && instData.length
                    ? instData.map((key, index) => (
                      <div className="tab-content-btn" key={index}>
                        <div
                          className="tabs-content-inner"
                          onClick={() => {
                            handleModal(key?.Institutes_name, "institute", key?.cid);
                          }}
                        >
                          {key?.Institutes_name}
                        </div>
                      </div>
                    ))
                    : ""}
                </div>
              </MDBTabsPane>
              <MDBTabsPane show={basicActive === "tab2"}>
                <h6 className="mt-4 mt-md-0">Select Your Preferred Study Material Below</h6>
                <div className="tabs-content">
                  {Array.isArray(studyData?.moduls) &&
                    studyData?.moduls.map((items, ind) => (
                      <div className="tab-content-btn" key={ind}>
                        <div
                          className="tabs-content-inner"
                          onClick={() => {
                            handleModal(items?.module_name, "studyMaterial");
                            disptach(getModule(items?.module_name));
                            setStudyMaterial(items?.module_name);
                          }}
                        >
                          {items?.module_name}
                        </div>
                      </div>
                    ))}
                </div>
              </MDBTabsPane>
            </MDBTabsContent>
          </div>
        </div>
      </section>

      {showModal && (
        <Modals
          show={showModal}
          close={handleModal}
          institute={institutename}
          addval={addVal}
          InstituteAdsVal={InstituteAdsVal}
          StudyMaterialAdsVal={StudyMaterialAdsVal}
          comesFrom={comesFrom}
          InstituteId={InstituteId}
          timerData={timerData}
        />
      )}

      {/* FAQ Section */}
      <section className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="faq_main_part">
                <div className="title mb-4">
                  <h2 className="text-part">Frequently Asked Questions</h2>
                </div>
                <AccordionCard />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonial />

      {/* Enhanced Newsletter Subscription Section */}
      <section id="section-cta" className="py-5 bg-light">
        <div className="sep-background-mask"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 text-white">
              <h3 className="fw-bold ">Subscribe to Newsletter</h3>
              <p className="mb-4">Stay informed about our latest news and events.</p>
            </div>
            <div className="col-md-8">
              <form onSubmit={handleSubmit(handleSubscriberFun)} className="row">
                <div className="col-md-4 col-sm-6">
                  <input
                    className="form-control w-100"
                    placeholder="Enter Name"
                    {...register("name", { required: true })}
                  />
                  {errors?.name && <p className="text-danger">Name is required.</p>}
                </div>
                <div className="col-md-4 col-sm-6">
                  <input
                    className="form-control w-100"
                    placeholder="Phone No."
                    {...register("number", { required: true })}
                  />
                  {errors?.number && <p className="text-danger">Number is required.</p>}
                </div>
                <div className="col-md-4 col-sm-12">
                  <input
                    className="form-control w-100"
                    placeholder="Enter Email"
                    {...register("email", { required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/ })}
                  />
                  {errors.email && errors.email.type === "required" && <p className="text-danger">Email is required.</p>}
                  {errors.email && errors.email.type === "pattern" && <p className="text-danger">Invalid email format.</p>}
                </div>
                <div className="col-md-12">
                  <textarea
                    style={{
                      height: '100px',
                      resize: 'none'
                    }}
                    className="form-control w-100"
                    placeholder="Enter message here..."
                    {...register("message", { required: true })}
                  />
                  {errors?.message && <p className="text-danger">Message is required.</p>}
                </div>
                <div className="col-md-12">
                  <button className="btn btn-primary w-100" type="submit">
                    <i className="fa fa-paper-plane"></i> Subscribe
                  </button>
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>



  );
};

export default Home;
