import React, { useEffect } from "react";

const AdsComponent = (props) => {
  const { dataAdSlot } = props;

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  return (
    <>
      <div
        style={{
          // border: "2px solid red",
          position: "absolute",
          bottom: "0",
          height: "100%",
          width: "100%",
        }}
      >
        <ins
          className="adsbygoogle"
          style={{ display: "block", height: "auto", border: "none" }}
          data-ad-client="ca-pub-2178006875471084"
          data-ad-slot={dataAdSlot}
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
    </>
  );
};

export default AdsComponent;
